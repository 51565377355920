<template>
  <b-modal id="dialog_group_permission" title="Permission">
    <template #modal-title>
      <h3><i class="fa fa-lock"></i> กำหนดสิทธิ์การใช้งาน</h3>
    </template>
    <table class="table table-bordered table-sm">
      <thead>
          <tr class="text-center">
            <th>Application</th>
            <th>Select All</th>
            <th>View</th>
            <th>Add</th>
            <th>Edit</th>
            <th>Remove</th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="app in permissions" :key="app.app_name">
            <td>{{app.app_name}}</td>
            <td class="text-center">
              <b-form-checkbox plain @change="selectAll(app)" v-model="app.selectAll" :check="checkSelectAll(app)"/>
            </td>
            <td class="text-center">
              <b-form-checkbox plain v-model="app.action.flag_view"/>
            </td>
            <td class="text-center">
              <b-form-checkbox plain v-model="app.action.flag_insert"/>
            </td>
            <td class="text-center">
              <b-form-checkbox plain v-model="app.action.flag_update"/>
            </td>
            <td class="text-center">
              <b-form-checkbox plain v-model="app.action.flag_delete"/>
            </td>
          </tr>
      </tbody>
    </table>
    <template #modal-footer>
      <b-button variant="primary" @click="onEditHandler()" class="style_btn"><i class="fa fa-check"></i> บันทึก</b-button>
      <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
  export default {
    name: 'acctrl-view-dialog-group-permission',
    data() {
      return {
      }
    },
    props: [
      "groupId", "permissions"
    ],
    methods: {
      async onEditHandler () {
        const result = await this.HttpServices.postData(`/ab_groups/permission/${this.groupId}`, {permissions: JSON.stringify(this.permissions)});
        if(result&&result.status.code=="200"){
          this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
          this.$bvModal.hide('dialog_group_permission');
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }
      },
      close () {
        this.$bvModal.hide('dialog_group_permission');
      },
      selectAll(item){
        item.action.flag_view = item.selectAll;
        item.action.flag_insert = item.selectAll;
        item.action.flag_update = item.selectAll;
        item.action.flag_delete = item.selectAll;
      },
      checkSelectAll(item){
        if(item.action.flag_view&&item.action.flag_insert&&item.action.flag_update&&item.action.flag_delete){
          item.selectAll = true;
        }else{
          item.selectAll = false;
        }
      }
    }
  }
</script>
