<template>
  <b-modal id="dialog_group_add" size="lg">
    <template #modal-title>
      <h3><i class="fa fa-plus"></i> เพื่มกลุ่มผู้ใช้งาน</h3>
    </template>
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <b-form @submit.prevent="handleSubmit(addHandler)">
        <div>
          <div>
            <div class="mb-2 row">
              <label class="col-sm-2 col-form-label label_algin_right label-required">ชื่อกลุ่ม</label>
              <div class="col-sm-10">
                <base-input name="name"
                            :rules="{required: true}"
                            placeholder="ชื่อกลุ่ม"
                            v-model="group.name">
                </base-input>
              </div>
            </div>
            <div class="mb-2 row">
              <label class="col-sm-2 col-form-label label_algin_right">รายละเอียด</label>
              <div class="col-sm-10">
                <textarea class="form-control"
                          v-model="group.detail"
                          style="height: 100px;" />
              </div>
            </div>
          </div>
        </div>
        <button ref="addGroupForm" style="display:none;">Submit</button>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button variant="primary" @click="onOutsideSubmit()"><i class="fa fa-check"></i> บันทึก</b-button>
      <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
  export default {
    name: 'acctrl-view-dialog-group-add',
    data() {
      return {
      }
    },
    props: [
      'group', 'successFuction'
    ],
    methods: {
      onOutsideSubmit(){
        this.$refs.addGroupForm.click();
      },
      async addHandler () {
        const result = await this.HttpServices.postData("/ab_groups", this.group);
        if(result&&result.status.code=="200"){
          this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
          this.$bvModal.hide('dialog_group_add');
          this.$emit("successFuction", "");
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }
      },
      close () {
        this.$bvModal.hide('dialog_group_add');
      }
    }
  }
</script>
