<template>
  <b-modal id="dialog_group_edit" size="lg">
    <template #modal-title>
      <h3><i class="fa fa-edit"></i> แก้ไขกลุ่มผู้ใช้งาน</h3>
    </template>
    <div>
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <b-form @submit.prevent="handleSubmit(editHandler)">
          <div>
            <div class="mb-2 row">
              <label class="col-sm-2 col-form-label label_algin_right label-required">ชื่อกลุ่ม</label>
              <div class="col-sm-10">
                <base-input name="name"
                            :rules="{required: true}"
                            placeholder="ชื่อกลุ่ม"
                            v-model="group.name">
                </base-input>
              </div>
            </div>
            <div class="mb-2 row">
              <label class="col-sm-2 col-form-label label_algin_right">รายละเอียด</label>
              <div class="col-sm-10">
                <textarea class="form-control"
                          v-model="group.detail"
                          style="height: 100px;" />
              </div>
            </div>
          </div>
        <button ref="editGroupForm" style="display:none;">Submit</button>
        </b-form>
      </validation-observer>
    </div>
    <template #modal-footer>
      <b-button variant="primary" @click="onOutsideSubmit()"><i class="fa fa-check"></i> บันทึก</b-button>
      <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
  export default {
    name: 'acctrl-view-dialog-group-edit',
    data() {
      return {
      }
    },
    props: [
      'group', 'successFunction'
    ],
    methods: {
      onOutsideSubmit(){
        this.$refs.editGroupForm.click();
      },
      async editHandler () {
        const result = await this.HttpServices.putData(`/ab_groups/${this.group.id}`, this.group);
        if(result&&result.status.code=="200"){
          this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
          this.$bvModal.hide('dialog_group_edit');
          this.$emit("successFuction", "");
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }
      },
      close () {
        this.$bvModal.hide('dialog_group_edit');
      }
    },
  }
</script>
