<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-users"></i> กลุ่มผู้ใช้งาน </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <accctrl_group/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import accctrl_group from "./view/page.group.vue";

  export default {
    components: {
      accctrl_group : accctrl_group
    },
    data() {
      return {
      }
    }
  };
</script>
<style>

</style>
